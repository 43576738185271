import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Spin, Modal} from 'antd';
import cache from "../../../api";
import api from "../../../api";
import ReplyForm from "./ReplyForm";
import {
    MailOutline
} from "@mui/icons-material";
import EmailItem from "./EmailItem";


const EmailDetail = ({emailThread, refreshList}) => {
    const [loading, setLoading] = useState(false);
    const [emailDetail, setEmailDetail] = useState(null);
    const [isReplying, setIsReplying] = useState(false);
    const [isForwarding, setIsForwarding] = useState(false);

    const emailDetailList = useRef();

    const refreshEmailDetails = useCallback(async () => {
        try {
            const cacheDetail = await cache.mailbox.thread.get(emailThread.id);
            setEmailDetail(cacheDetail);

            setLoading(true);

            const detail = await api.mailbox.thread.get(emailThread.id);
            setEmailDetail(detail);
        } catch (e) {
            Modal.error({
                title: "Unable to fetch emails: " + e,
                okCancel: false,
            });
        } finally {
            setLoading(false);
        }
    }, [emailThread]);


    useEffect(() => {
        if (emailDetail && !emailDetail.thread[0].read_at) {
            const timeout = setTimeout(async () => {
                await api.mailbox.thread.read(emailThread.id, true);
                refreshList(true, true);
            }, 3000);

            return (() => {
                clearTimeout(timeout);
            });
        }
        //eslint-disable-next-line
    }, [emailDetail, emailThread])


    useEffect(() => {
        if (emailThread) {
            refreshEmailDetails().then();
        } else {
            setEmailDetail(null);
            setLoading(false);
        }
        setIsReplying(false);
        setIsForwarding(false);
    }, [emailThread, refreshEmailDetails])


    const onReplySubmited = () => {
        refreshList()
        refreshEmailDetails();
    };


    const handleArchive = async () => {
        setLoading(true);
        try {
            await api.mailbox.thread.archive(emailThread.id);
            refreshList();
        } catch (e) {
            Modal.error({
                title: "Unable to archive the email: " + e,
                okCancel: false,
            });
        } finally {
            setLoading(false);
        }
    }
    const handleRestore = async () => {
        setLoading(true);
        try {
            await api.mailbox.thread.restore(emailThread.id);
            refreshList();
        } catch (e) {
            Modal.error({
                title: "Unable to unarchive the email: " + e,
                okCancel: false,
            });

        } finally {
            setLoading(false);
        }
    }

    const handleReply = () => {
        setIsReplying(true);
        setIsForwarding(false);
        emailDetailList?.current?.scrollTo(0, 0);
    }
    const handleForward = () => {
        setIsForwarding(true);
        setIsReplying(false);
        emailDetailList?.current?.scrollTo(0, 0);
    }

    const handleCancel = () => {
        setIsForwarding(false);
        setIsReplying(false);
    }
    const handleUnread = async () => {
        await api.mailbox.thread.read(emailThread.id, false);
        refreshList(true, true);
    }


    return (
        <>
            <div ref={emailDetailList} style={{display: 'flex', flexDirection: 'column', backgroundColor: '#f5f5f5', padding: '10px'}}
                 className="scrollable-list">

                {!emailThread && <div className="email-detail-no-selection">
                    <MailOutline/>
                    <span>Select an email</span>
                </div>}

                {loading && <Spin/>}

                {emailThread && emailDetail && (isReplying || isForwarding) &&
                    <ReplyForm emailDetail={emailDetail}
                               isReplying={isReplying}
                               isForwarding={isForwarding}
                               setLoading={setLoading}
                               onSubmited={onReplySubmited}
                               onCancel={handleCancel}/>}


                {emailThread && emailDetail && emailDetail.thread.map((email, index) => (
                    <EmailItem
                        key={index}
                        email={email}
                        emailThread={emailThread}
                        onUnread={handleUnread}
                        onReply={handleReply}
                        onArchive={handleArchive}
                        onRestore={handleRestore}
                        onForward={handleForward}/>
                ))}
            </div>
        </>
    );
};

export default EmailDetail;
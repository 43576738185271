const mailbox = () => {
    const cache = {
        folder: {
            get: {}
        },
        email: {
            stats: {}
        },
        thread: {
            get: {}
        }
    };

    return {
        folder: {
            get: async (folder) => {
                return cache.folder.get[folder];
            },
            cacheGet: async (folder, object) => {
                cache.folder.get[folder] = object;

            }
        },
        email: {
            stats: async (id) => {
                return cache.thread.get[id];
            },
            cacheStats: async (id, object) => {
                cache.thread.get[id] = object;
            }
        },
        thread: {
            get: async (id) => {
                return cache.thread.get[id];
            },
            cacheGet: async (id, object) => {
                cache.thread.get[id] = object;
            }
        }
    }
};

export default mailbox;
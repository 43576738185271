import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Menu, Modal} from 'antd';
import {AppstoreOutlined, FileTextOutlined, UserOutlined} from '@ant-design/icons';
import useUser from "../utils/useUser";
import {Logout, SettingsOutlined} from "@mui/icons-material";
import "./Navbar.css"

const Navbar = () => {
    const [user, saveUser] = useUser();
    const navigate = useNavigate();

    const handleLogout = () => {
        Modal.confirm({
            "title": "Do you want to logout?",
            onOk() {
                saveUser(null);
                navigate('/login');
            }
        });
    }


    const items = [
        {
            key: 'companies',
            icon: <AppstoreOutlined/>,
            label: <Link to="/companies">Companies</Link>
        },
        {
            key: 'campaigns',
            icon: <FileTextOutlined/>,
            label: <Link to="/campaigns">Campaigns</Link>
        },
        {
            key: 'mailbox',
            icon: <FileTextOutlined/>,
            label: <Link to="/mailbox">Mailbox</Link>
        },
        {
            className: "leftAlignedMenu user",
            label: user?.name,
            icon: <UserOutlined/>,
            children: [
                {
                    key: 'settings',
                    disabled: true,
                    icon: <SettingsOutlined/>,
                    label: <span>Settings</span>
                },
                {
                    key: 'logout',
                    icon: <Logout/>,
                    label: <span onClick={handleLogout}>Logout</span>
                },
            ]
        }

    ]
    return user ? <Menu className="navbar-menu" mode="horizontal" items={items}/> : <></>;
};

export default Navbar;

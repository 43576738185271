import React from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import './App.css';
import CompanyList from './routes/CompanyList';
import CampaignList from './routes/CampaignList';
import CampaignDetail from './routes/CampaignDetail';
import Mailbox from './routes/Mailbox';
import Navbar from './components/Navbar';
import Login from "./routes/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import {UserProvider} from "./providers/userContext";


function App() {

    return (

        <UserProvider>
            <BrowserRouter>
                <div className="App">
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<ProtectedRoute><Navigate to="/companies"/></ProtectedRoute>}/>
                        <Route path="/companies" element={<ProtectedRoute><CompanyList/></ProtectedRoute>}/>
                        <Route path="/campaigns" element={<ProtectedRoute><CampaignList/></ProtectedRoute>}/>
                        <Route path="/campaigns/:campaignId" element={<ProtectedRoute><CampaignDetail/></ProtectedRoute>}/>
                        <Route path="/mailbox" element={<ProtectedRoute><Mailbox/></ProtectedRoute>}/>
                        <Route path="/login" element={<Login/>}/>
                    </Routes>
                </div>
            </BrowserRouter>
        </UserProvider>
    );
}

export default App;

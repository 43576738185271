import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Card, Modal} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import "./login.css";
import {useNavigate} from "react-router-dom";
import useUser from "../../utils/useUser";
import api from "../../api";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [user, saveUser] = useUser();

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);


    const onFinish = async (values) => {
        setLoading(true);
        try {
            const result = await api.users.login(values.email, values.password)
            if (result) {
                saveUser(result);
            }
        } catch (e) {
            Modal.error({
                title: "Unable to login: " + e,
                okCancel: false,
            })
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <Card title="SFlow Login" style={{width: 300, textAlign: 'center'}}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: 'Please input your Email!'}]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Please input your Password!'}]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default Login;
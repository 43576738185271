import {Button, Tooltip} from "antd";
import React from "react";

const DisableableButton = ({tooltip, disabled, children, ...args}) => {


    const renderButton = () => (
        <Button disabled={disabled} {...args}>
            {children}
        </Button>
    );

    return disabled ? (
        <Tooltip title={tooltip}>
            <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                {renderButton()}
            </div>
        </Tooltip>
    ) : (
        renderButton()
    );
}
export default DisableableButton;
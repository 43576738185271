import React, {useEffect, useState} from "react";
import {Button, Card, Divider, Modal, Space, Table, Typography} from "antd";
import {
    AdsClick,
    ArchiveOutlined,
    AttachFile,
    MarkEmailUnreadOutlined,
    ReplyAllOutlined,
    ShortcutOutlined,
    UnarchiveOutlined,
    Visibility
} from "@mui/icons-material";
import {cleanBody, renderDateTime} from "../../../utils/helpers";
import api from "../../../api";
import cache from "../../../cache";

const {Title, Paragraph, Text} = Typography;

const EmailItem = ({
                       email, emailThread, onUnread, onReply, onForward, onRestore, onArchive
                   }) => {

    const [stats, setStats] = useState(null);
    const [statsLoading, setStatsLoading] = useState(false);
    const [statsVisible, setStatsVisible] = useState(false);

    const handleToggleStats = () => {
        setStatsVisible(!statsVisible);
    }


    useEffect(() => {
        if (statsVisible) {
            setStatsLoading(true);
            (async () => {
                try {
                    const cached = await cache.mailbox.email.stats(email.id);
                    if (cached) {
                        setStats(cached);
                    }

                    const data = await api.mailbox.email.stats(email.id);
                    setStats(data);
                } catch (e) {
                    setStatsVisible(false);
                    Modal.error({
                        title: "Unable to load stats: " + e,
                        okCancel: false,
                    });
                }
                setStatsLoading(false);
            })();
        }
    }, [statsVisible, email])


    const statsColumns = [
        {title: 'Date', dataIndex: 'created_at', key: 'created_at', render: renderDateTime},
        {title: 'Clicks', dataIndex: 'clicks', key: 'clicked'},
        {title: 'Views', dataIndex: 'views', key: 'view'},
    ];



    return <Card className="email-detail-card">
        <Space direction="vertical" style={{width: '100%', textAlign: 'left'}}>
            <div className="email-detail-topbar">
                <div className="email-detail-infos">
                    <Title level={5} style={{marginBottom: 0, marginTop: 0}}>
                        {email.from}
                    </Title>
                    <Paragraph style={{fontWeight: 'bold', marginBottom: 0}}>To: {email.to}</Paragraph>
                    {email.cc && <Paragraph style={{fontWeight: 'bold', marginBottom: 0}}>CC: {email.cc}</Paragraph>}
                    <Paragraph style={{fontWeight: 'bold'}}>Subject: {email.subject}</Paragraph>

                    {email.status === 2 && <>
                        <div className="email-detail-statsbar" onClick={handleToggleStats}>
                            <div>
                                <Visibility fontSize={"small"}/> <span>{email.views} <small>views</small></span>
                            </div>
                            <div>
                                <AdsClick fontSize={"small"}/> <span>{email.clicks} <small>clicks</small></span>
                            </div>
                        </div>
                        <div className={`email-detail-stats-full ${!statsVisible ? 'hidden' : ''}`}>
                            { <Table loading={statsLoading} dataSource={stats} columns={statsColumns} rowKey="id" pagination={{hideOnSinglePage: true}}/>}
                        </div>
                    </>
                    }


                </div>
                <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
                    <Space>
                        <Button onClick={onUnread} icon={<MarkEmailUnreadOutlined/>}
                                style={{border: 'none', backgroundColor: '#f5f5f5'}}/>
                        <Button onClick={onReply} icon={<ReplyAllOutlined/>}
                                style={{border: 'none', backgroundColor: '#f5f5f5'}}/>
                        <Button onClick={onForward} icon={<ShortcutOutlined/>}
                                style={{border: 'none', backgroundColor: '#f5f5f5'}}/>
                        {emailThread.archived ? <Button onClick={onRestore} icon={<UnarchiveOutlined/>}
                                                        style={{border: 'none', backgroundColor: '#f5f5f5'}}/> : null
                        }
                        {!emailThread.archived ? <Button onClick={onArchive} icon={<ArchiveOutlined/>}
                                                         style={{border: 'none', backgroundColor: '#f5f5f5'}}/> : null
                        }
                    </Space>

                    <Text type="secondary" style={{marginLeft: 5, marginTop: 5}}>
                        {renderDateTime(email.receivedAt)}
                    </Text>
                </div>
            </div>
            <Divider/>
            <Paragraph style={{whiteSpace: 'pre-wrap'}}>
                <div dangerouslySetInnerHTML={{__html: cleanBody(email.body)}}></div>
            </Paragraph>
            <div className={"attachment-container"}>
                {email.attachments.map(attachment => <a key={attachment.id} href={attachment.url} target="_blank" rel="noreferrer"
                                                        className="attachment-button">
                    <AttachFile/>
                    <p>
                        {attachment.name}<br/>
                        <small>(View attachment)</small>
                    </p>
                </a>)}

            </div>
        </Space>
    </Card>

}
export default EmailItem;
import React, {useState, useEffect} from 'react';
import ReactQuill from "react-quill";
import {Button, Input, Modal, Space, Spin, Typography} from "antd";
import useUser from "../../../utils/useUser";
import {Send} from "@mui/icons-material";
import api from "../../../api";

const {Title} = Typography;


const NewEmailForm = ({refreshList, visible, onCancel}) => {
    const [user] = useUser();
    const [subject, setSubject] = useState('');
    const [cc, setCc] = useState('');
    const [body, setBody] = useState('');
    const [to, setTo] = useState('');
    const [from, setFrom] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setFrom(user.email);
    }, [user])


    const handleSubmit = async () => {

        setLoading(true);
        try {
            const values = {from, to, subject, cc, body};
            await api.mailbox.email.create(values);
            refreshList(true);
            handleCancelNewEmail();
        } catch (e) {
            Modal.error({
                title: "Unable to send the email: " + e,
                okCancel: false,
            });

        } finally {
            setLoading(false);
        }
    };

    const handleCancelNewEmail = () => {
        setTo('');
        setSubject('');
        setCc('');
        setBody('');
        onCancel();
    };


    return (
        <Modal
            open={visible}
            width={'70vw'}
            style={{maxWidth: '1500px'}}
            onCancel={handleCancelNewEmail}
            footer={null}
        >

            {loading && <div className={"flexCenterButton"} style={{position: "absolute", top:0, left:0, right: 0, bottom: 0}}><Spin size={"large"}/></div>}

            <Space direction="vertical" style={{width: '100%', textAlign: 'left', opacity: loading ? '40%' : '', pointerEvents: loading ? 'none' : ''}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Title level={5} style={{marginBottom: 0, marginTop: 0}}>
                            From: {from}
                        </Title>
                        <Input value={to} style={{marginTop: 10}} onChange={e => setTo(e.target.value)} placeholder="TO"/>
                        <Input value={cc} style={{marginTop: 5}} onChange={e => setCc(e.target.value)} placeholder="CC"/>
                        <Input value={subject} style={{marginTop: 5}} onChange={e => setSubject(e.target.value)} placeholder="Subject"/>
                    </div>
                </div>
                <ReactQuill value={body} onChange={setBody} style={{height: '200px', marginBottom: '50px'}}/>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>

                    {/*<Upload>*/}
                    {/*    <Button  style={{marginRight: 10}}><Icon icon={faFileUpload} style={{marginRight: 10}}/>Add Attachment</Button>*/}
                    {/*</Upload>*/}
                    <Button key="cancel" onClick={handleCancelNewEmail} style={{marginRight: 10}}>
                        Cancel
                    </Button>
                    <Button className="flexCenterButton" type="primary" onClick={handleSubmit}>Send <Send style={{marginLeft: 10}} fontSize={"small"}/></Button>
                </div>
            </Space>
        </Modal>

    );
}

export default NewEmailForm;
import jwt_decode from "jwt-decode";
const users = (apiClient, handleError) => {
    return {
        login: async (email, password) => {
            try {
                const {data} = await apiClient.post(`/users/login`, {
                    email,
                    password
                });
                const decoded = jwt_decode(data.token);
                return {...decoded, ...data};
            } catch (e) {
                handleError(e);
            }
        }
    }
}

export default users;
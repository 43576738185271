import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import {arraysEqual, campaignStatus} from "../../../utils/helpers";
import DisableableButton from "../../../components/DisableableButton";

const TabContacts = ({
                         campaignDetails,
                         handleUpdateSelectedContacts,
                         loading
                     }) => {


    const [originalSelectedContacts, setOriginalSelectedContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selectedChanged, setSelectedChanged] = useState(false);


    useEffect(() => {
        if (!arraysEqual(selectedContacts, originalSelectedContacts)) {
            setSelectedChanged(true);
        } else {
            setSelectedChanged(false);
        }
    }, [selectedContacts, originalSelectedContacts])

    useEffect(() => {
        setSelectedContacts(
            campaignDetails.companies.flatMap((company) => {
                return company.contacts
                    .filter((contact) => contact.ready_to_send === 1)
                    .map((contact) => contact.id);
            })
        );
        setOriginalSelectedContacts(
            campaignDetails.companies.flatMap((company) => {
                return company.contacts
                    .filter((contact) => contact.ready_to_send === 1)
                    .map((contact) => contact.id);
            })
        );
    }, [campaignDetails]);


    if (!campaignDetails) {
        return <></>
    }


    const onClickUpdateSelectedContacts = () => {
        handleUpdateSelectedContacts(selectedContacts);
    };

    const handleRowClick = (record) => {
        if(record.status !== 0)
            return;

        const isSelected = selectedContacts.includes(record.id);
        if (isSelected) {
            setSelectedContacts(selectedContacts.filter(id => id !== record.id));
        } else {
            setSelectedContacts([...selectedContacts, record.id]);
        }
    };
    const checkCompanyRepeat = (text, record, index) => {
        const previousRecord =
            index > 0 ? contactResults[index - 1] : null;
        if (
            !previousRecord ||
            previousRecord.companyName !== record.companyName
        ) {
            return text;
        }
        return '';
    }

    const updateSelectionStatusDisabled = campaignDetails.current_step !== campaignStatus.linkedin_searching && campaignDetails.current_step !== campaignStatus.linkedin_finished;
    const updateSelectionEnabled = !updateSelectionStatusDisabled && selectedChanged;
    const updateSelectionEnabledHint = updateSelectionStatusDisabled ? "Status doesn't allow to update selection." : (!selectedChanged ? 'You need to change the selection to update them.' : '')



    const columns = [
        {title: "Company Name", dataIndex: "companyName", key: "companyName", render: checkCompanyRepeat},
        {title: "Name", dataIndex: "first_name", key: "first_name"},
        {title: "Last Name", dataIndex: "last_name", key: "last_name"},
        {title: "Job Title", dataIndex: "job_title", key: "job_title"},
        {title: "Email", dataIndex: "email", key: "email"},
    ];

    if(campaignDetails.current_step === campaignStatus.email_sending || campaignDetails.current_step === campaignStatus.email_finished) {
        columns.push({
            title: 'Status',
            dataIndex: 'id',
            render: (text, record) => (record.status === 1 ? "Sent" : ""),
        });
    }

    if(!updateSelectionStatusDisabled) {
        columns.push({
            title: '',
            dataIndex: 'id',
            render: (text, record) => (
                !updateSelectionStatusDisabled ?
                    <input type="checkbox" checked={selectedContacts?.indexOf(record.id) !== -1}
                           onChange={() => handleRowClick(record)}/> : <></>
            ),
        })
    }

    const contactResults = campaignDetails.companies.flatMap((company) =>
        company.contacts.map((contact) => ({
            ...contact,
            companyName: company.name,
        }))
    );

    return (
        <>
            <Table
                loading={loading}
                dataSource={contactResults}
                columns={columns}
                pagination={false}
                rowKey="id"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            />
            <div style={{marginTop: '16px'}}>
                <DisableableButton type="primary" tooltip={updateSelectionEnabledHint} disabled={!updateSelectionEnabled} onClick={onClickUpdateSelectedContacts}>
                    Update Selected Contacts
                </DisableableButton>
            </div>
        </>
    );
};

export default TabContacts;
import React, {useEffect, useState} from 'react';
import {Modal, Table} from 'antd';
import {arraysEqual, campaignStatus} from "../../../utils/helpers";
import DisableableButton from "../../../components/DisableableButton";

const TabLinkedinResults = ({
                                campaignDetails,
                                handleUpdateSelectedJobs,
                                handleSendEmail,
                                loading
                            }) => {


    const [originalSelectedOpenings, setOriginalSelectedOpenings] = useState([]);
    const [selectedOpenings, setSelectedOpenings] = useState([]);
    const [selectedChanged, setSelectedChanged] = useState(false);


    useEffect(() => {
        if (!arraysEqual(selectedOpenings, originalSelectedOpenings)) {
            setSelectedChanged(true);
        } else {
            setSelectedChanged(false);
        }
    }, [selectedOpenings, originalSelectedOpenings])

    useEffect(() => {
        setSelectedOpenings(
            campaignDetails.companies.flatMap((company) => {
                return company.openings
                    .filter((opening) => opening.ready_to_send === 1)
                    .map((opening) => opening.id);
            })
        );
        setOriginalSelectedOpenings(
            campaignDetails.companies.flatMap((company) => {
                return company.openings
                    .filter((opening) => opening.ready_to_send === 1)
                    .map((opening) => opening.id);
            })
        );
    }, [campaignDetails]);


    if (!campaignDetails) {
        return <></>
    }


    const onClickUpdateSelectedJobs = () => {
        handleUpdateSelectedJobs(selectedOpenings);
    };

    const onClickSendEmail = () => {
        if (selectedChanged) {
            Modal.warn({
                title: "You have changes in selection not saved.",
                okCancel: false,
            })
        } else {
            Modal.confirm({
                "title": "Do you want to send the emails associated with this campaign?",
                onOk() {
                    handleSendEmail();
                }
            });

        }
    };


    const handleRowClick = (record) => {
        const isSelected = selectedOpenings.includes(record.id);
        if (isSelected) {
            setSelectedOpenings(selectedOpenings.filter(id => id !== record.id));
        } else {
            setSelectedOpenings([...selectedOpenings, record.id]);
        }
    };
    const checkCompanyRepeat = (text, record, index) => {
        const previousRecord =
            index > 0 ? linkedinResults[index - 1] : null;
        if (
            !previousRecord ||
            previousRecord.companyName !== record.companyName
        ) {
            return text;
        }
        return '';
    }

    const columns = [
        {title: "Company Name", dataIndex: "companyName", key: "companyName", render: checkCompanyRepeat},
        {title: "Role Name", dataIndex: "role_name", key: "role_name"},
        {title: "Link", dataIndex: "link", key: "link"},
        {title: "Opened At", dataIndex: "opened_at", key: "opened_at"},
        {
            title: '',
            dataIndex: 'id',
            render: (text, record) => (
                !updateSelectionStatusDisabled ?
                <input type="checkbox" checked={selectedOpenings?.indexOf(record.id) !== -1}
                       onChange={() => handleRowClick(record)}/> : <></>
            ),
        }
    ];

    const linkedinResults = campaignDetails.companies.flatMap((company) =>
        company.openings.map((opening) => ({
            ...opening,
            companyName: company.name,
        }))
    );

    const updateSelectionStatusDisabled = campaignDetails.current_step !== campaignStatus.linkedin_searching && campaignDetails.current_step !== campaignStatus.linkedin_finished;
    const updateSelectionEnabled = !updateSelectionStatusDisabled && selectedChanged;
    const updateSelectionEnabledHint = updateSelectionStatusDisabled ? "Status doesn't allow to update selection." : (!selectedChanged ? 'You need to change the selection to update them.' : '')

    const sendEmailsEnabled = campaignDetails.current_step === campaignStatus.linkedin_finished && originalSelectedOpenings.length > 0;
    const sendEmailsEnabledHint = campaignDetails.current_step !== campaignStatus.linkedin_finished ? "Status doesn't allow to start sending emails." : (originalSelectedOpenings.length === 0 ? 'You need to select at least one opening' : '')

    return (
        <>
            <Table
                loading={loading}
                dataSource={linkedinResults}
                columns={columns}
                pagination={false}
                rowKey="id"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
            />
            <div style={{marginTop: '16px'}}>
                <DisableableButton type="primary" tooltip={updateSelectionEnabledHint} disabled={!updateSelectionEnabled} onClick={onClickUpdateSelectedJobs}>
                    Update Selected Jobs
                </DisableableButton>
                <DisableableButton tooltip={sendEmailsEnabledHint} type="primary" disabled={!sendEmailsEnabled} onClick={onClickSendEmail}
                        style={{marginLeft: '16px'}}>
                    Send Emails
                </DisableableButton>
            </div>
        </>
    );
};

export default TabLinkedinResults;
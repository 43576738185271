const mailbox = (apiClient, handleError, cache) => {
    return {
        folder: {
            get: async (folder) => {
                try {
                    const {data} = await apiClient.get(`/mailbox/folders/${folder}`);
                    await cache.mailbox.folder.cacheGet(folder, data);
                    return data;
                } catch (e) {
                    handleError(e);
                }
            }
        },
        thread: {
            get: async (id) => {
                try {
                    const {data} = await apiClient.get(`/mailbox/threads/${id}`);
                    await cache.mailbox.thread.cacheGet(id, data);
                    return data;
                } catch (e) {
                    handleError(e);
                }
            },
            archive: async (id) => {
                try {
                    const {data} = await apiClient.delete(`/mailbox/threads/${id}`);
                    return data;
                } catch (e) {
                    handleError(e);
                }
            },
            restore: async (id) => {
                try {
                    const {data} = await apiClient.patch(`/mailbox/threads/${id}`, {
                        archived: 0
                    });
                    return data;
                } catch (e) {
                    handleError(e);
                }
            },
            read: async (id, read) => {
                try {
                    const {data} = await apiClient.patch(`/mailbox/threads/${id}`, {
                        read
                    });
                    return data;
                } catch (e) {
                    handleError(e);
                }
            },
        },
        email: {
            reply: async (id, email) => {
                try {
                    const {data} = await apiClient.post(`/mailbox/emails/${id}`, {
                        ...email
                    });
                    return data;
                } catch (e) {
                    handleError(e);
                }
            },
            stats: async (id) => {
                try {
                    const {data} = await apiClient.get(`/mailbox/emails/${id}/stats`);
                    await cache.mailbox.email.cacheStats(id, data);
                    return data;
                } catch (e) {
                    handleError(e);
                }
            },
            create: async (email) => {
                try {
                    const {data} = await apiClient.post(`/mailbox/emails`, {
                        ...email
                    });
                    return data;
                } catch (e) {
                    handleError(e);
                }
            },
        }
    }
};

export default mailbox;
import React, {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {Table, Tabs, Spin, Descriptions, Modal} from 'antd';
import {campaignStatus, statusTranslator} from "../../utils/helpers";
import TabLinkedinResults from "./components/TabLinkedinResults";
import api from "../../api";
import TabContacts from "./components/TabContacts";
import TabEmails from "./components/TabEmails";

const {TabPane} = Tabs;

const CampaignDetail = () => {
    const {campaignId} = useParams();
    const [campaignDetails, setCampaignDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingLinkedin, setLoadingLinkedin] = useState(false);
    const [loadingContact, setLoadingContact] = useState(false);

    const fetchCampaignDetails = useCallback(async () => {
        try {
            const data = await api.campaigns.getDetail(campaignId);
            setCampaignDetails(data);
        } catch (error) {
            console.error('Error fetching campaign details:', error);
        } finally {
            setLoading(false);
        }
    }, [campaignId]);

    useEffect(() => {

        fetchCampaignDetails();
    }, [campaignId, fetchCampaignDetails]);


    const columnsTab1 = [
        {title: 'Name', dataIndex: 'name', key: 'name'},
        {title: 'Website', dataIndex: 'website', key: 'website'},
        {title: 'Industry', dataIndex: 'industry', key: 'industry'},
        {title: 'Revenue', dataIndex: 'revenue', key: 'revenue'},
        {title: 'Employee Count', dataIndex: 'employee_count', key: 'employee_count'},
    ];


    const handleSendEmail = async () => {
        setLoading(true);
        try {
            await api.campaigns.sendEmail(campaignId);
            await fetchCampaignDetails();
        } catch (error) {
            Modal.error({
                title: error,
                okCancel: false,
            });
        } finally {
            setLoading(false);
        }

    };

    const handleUpdateSelectedJobs = async (openings_selected) => {
        setLoadingLinkedin(true);
        try {
            await api.campaigns.updateSelectedOpening(campaignId, openings_selected);
            await fetchCampaignDetails();
        } catch (error) {
            Modal.error({
                title: error,
                okCancel: false,
            });
        } finally {
            setLoadingLinkedin(false);
        }
    };
    const handleUpdateSelectedContacts = async (contacts_selected) => {
        setLoadingContact(true);
        try {
            await api.campaigns.updateSelectedContacts(campaignId, contacts_selected);
            await fetchCampaignDetails();
        } catch (error) {
            Modal.error({
                title: error,
                okCancel: false,
            });
        } finally {
            setLoadingContact(false);
        }
    };


    if (loading) {
        return <Spin/>;
    } else if (!campaignDetails) {
        return <div>Could not find campaign detail...</div>;
    }

    return (
        <div style={{padding: '16px'}}>

            <Descriptions title={`${campaignDetails.name} (${new Date(campaignDetails.created_at).toLocaleDateString()})`} layout="horizontal" bordered>
                <Descriptions.Item label="Created At">{new Date(campaignDetails.created_at).toLocaleDateString()}</Descriptions.Item>
                <Descriptions.Item label="Status" span={2}>{statusTranslator(campaignDetails.current_step)}</Descriptions.Item>
                <Descriptions.Item label="Role Pattern" span={3}>{campaignDetails.role_pattern}</Descriptions.Item>
                <Descriptions.Item label="Subject Template" span={3}>{campaignDetails.subject_template}</Descriptions.Item>
                <Descriptions.Item label="Email Template" span={3}>
                    <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: campaignDetails.email_template}}></div>
                </Descriptions.Item>
            </Descriptions>


            <Tabs defaultActiveKey="1" style={{marginTop: "24px"}} type={"card"}>
                <TabPane tab="Selected Companies" key="1">
                    <Table dataSource={campaignDetails.companies} columns={columnsTab1} rowKey="id" pagination={false}/>
                </TabPane>
                <TabPane tab="Contacts" key="2">
                    <TabContacts campaignDetails={campaignDetails}
                                 handleUpdateSelectedContacts={handleUpdateSelectedContacts}
                                 loading={loadingContact}
                    />
                </TabPane>
                <TabPane disabled={campaignDetails?.current_step === campaignStatus.linkedin_waiting} tab="LinkedIn Results"
                         key="3">
                    <TabLinkedinResults
                        campaignDetails={campaignDetails}
                        handleSendEmail={handleSendEmail}
                        handleUpdateSelectedJobs={handleUpdateSelectedJobs}
                        loading={loadingLinkedin}
                    />

                </TabPane>
                <TabPane
                    disabled={campaignDetails?.current_step !== campaignStatus.email_sending && campaignDetails?.current_step !== campaignStatus.email_finished}
                    tab="Emails" key="4">
                    <TabEmails campaignDetails={campaignDetails}/>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default CampaignDetail;
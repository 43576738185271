const companies = (apiClient, handleError) => {
    return {
        list: async (revenue, name, website, employee_count, industry, page, itemsPerPage) => {
            try {
                const {data} = await apiClient.get(`/companies`, {
                    params: {
                        name,
                        website,
                        revenue: revenue.join("|"),
                        employee_count: employee_count.join("|"),
                        industry: industry.join("|"),
                        perPage: itemsPerPage,
                        page,
                    },
                });
                return data;
            } catch (error) {
                handleError(error);
            }
        },
        industries: async () => {
            try {
                const {data} = await apiClient.get(`/companies/industries`);
                return data.filter(industry => industry || false);
            } catch (error) {
                handleError(error);
            }
        },
    }
}
export default companies;
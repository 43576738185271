import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Button, Table, Row, Col} from 'antd';
import {renderDateTime, statusTranslator} from "../utils/helpers";
import api from "../api";

const CampaignList = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchJobs = async () => {
            setLoading(true);
            try {
                const data = await api.campaigns.list();
                setJobs(data);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobs();
    }, []);


    const linkButton = (campaign) => {
        return <Link to={`/campaigns/${campaign.id}`}>
            <Button type="primary">View Details</Button>
        </Link>
    }


    const columns = [
        {title: 'User', dataIndex: 'user_name', key: 'user_name'},
        {title: 'Name', dataIndex: 'name', key: 'name'},
        {title: 'Companies selected', dataIndex: 'companies_count', key: 'companies_count'},
        {title: 'Role Pattern', dataIndex: 'role_pattern', key: 'role_pattern'},
        {title: 'Created at', dataIndex: 'created_at', key: 'created_at', render: renderDateTime},
        {title: 'Status', dataIndex: 'current_step', key: 'current_step', render: statusTranslator},
        {title: '', key: 'button', render: linkButton},
    ]

    return (
        <div>
            <h1>Campaigns</h1>

            <Row style={{padding: '16px'}}>
                <Col span={24}>
                    <Table
                        dataSource={jobs}
                        columns={columns}
                        rowKey="id"
                        pagination={false}
                        size={"small"}
                        loading={loading}
                    />
                </Col>
            </Row>
        </div>

    );
};

export default CampaignList;

import React, {useState, useEffect} from 'react';
import ReactQuill from "react-quill";
import {Button, Card, Input, Modal, Space, Typography} from "antd";
import useUser from "../../../utils/useUser";
import api from "../../../api";
import {cleanBody, renderDateTime} from "../../../utils/helpers";


const {Title, Paragraph} = Typography;

const ReplyForm = ({emailDetail, onSubmited, isReplying, isForwarding, setLoading, onCancel}) => {
    const [user] = useUser();
    const [subject, setSubject] = useState('');
    const [cc, setCc] = useState('');
    const [body, setBody] = useState('');
    const [to, setTo] = useState('');
    const [from, setFrom] = useState('');


    useEffect(() => {

        const mountThreadHistory = () => {
            return emailDetail.thread.map((thread) => {
                return `
            <b>From:</b> ${thread.from} <br/>
            <b>Date:</b> ${renderDateTime(thread.created_at)}<br/>
            <b>Subject:</b>  ${thread.subject}<br/>
            ${thread.cc ? `<b>CC:</b> ${thread.cc}<br/>` : ''}
            <b>To:</b>  ${thread.to}<br/><br/>
            ${cleanBody(thread.body)}`
            }).join("<br/>__________________________________________________________________<br/>");
        }

        setFrom(user.email);

        const email = emailDetail.thread[0];
        setSubject(email.subject);

        if (isReplying) {
            if (email.from !== user.email) {
                setTo(email.from);
            } else {
                setTo(email.to);
            }
            setCc(email.cc);
            setBody("");
        } else {
            setBody("<br/><br/>---------- Forwarded message ---------<br/>" + mountThreadHistory());
        }

    }, [user, emailDetail, isReplying, isForwarding])



    const handleSubmit = async () => {
        const email = emailDetail.thread[0];
        const values = {from, to, subject, cc, body, replyingTo: email.id};
        setLoading(true);
        try {
            if (isReplying) {
                await api.mailbox.email.reply(emailDetail.id, values);
            } else if (isForwarding) {
                await api.mailbox.email.create(values);
            }
            onSubmited();

            setSubject('');
            setCc('');
            setBody('');
        } catch (e) {
            Modal.error({
                title: "Unable to send the email: " + e,
                okCancel: false,
            });

        } finally {
            setLoading(false);
        }
    };
    return (
        <Card className="email-detail-card">
            <Space direction="vertical" style={{width: '100%', textAlign: 'left'}}>
                <div className="email-detail-topbar">
                    <div className="email-detail-infos">
                        <Title level={5} style={{marginBottom: 0, marginTop: 0}}>
                            From: {from}
                        </Title>
                        {isReplying && <Paragraph style={{fontWeight: 'bold', marginBottom: 0}}>To: {to}</Paragraph>}
                        {isForwarding && <Input value={to} style={{marginTop: 10}} onChange={e => setTo(e.target.value)} placeholder="To"/>}
                        <Input value={cc} style={{marginTop: 10}} onChange={e => setCc(e.target.value)} placeholder="CC"/>
                        <Input value={subject} style={{marginTop: 5}} onChange={e => setSubject(e.target.value)} placeholder="Subject"/>
                    </div>
                </div>
                <ReactQuill value={body} onChange={setBody} style={{height: '200px', marginBottom: '50px'}}/>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>

                    {/*<Upload>*/}
                    {/*    <Button  style={{marginRight: 10}}><Icon icon={faFileUpload} style={{marginRight: 10}}/>Add Attachment</Button>*/}
                    {/*</Upload>*/}

                    <Button type="ghost" onClick={onCancel}>Cancel</Button>
                    <Button type="primary" onClick={handleSubmit}>Send</Button>
                </div>
            </Space>
        </Card>

    );
}

export default ReplyForm;
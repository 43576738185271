// EmailList.js
import React from 'react';
import {List} from 'antd';
import moment from 'moment';
import {Circle} from "@mui/icons-material";

const EmailList = ({emails, onSelectEmail, selectedEmail, loading}) => {
    return (
        <List
            className="scrollable-list"
            itemLayout="horizontal"
            loading={loading}
            style={{marginRight: 5}}
            dataSource={emails}
            renderItem={emailThread => {
                const email = emailThread.thread[0];
                const sending = email.status !== 1; //0=PENDING, 1=RECEIVED, 2=SENT
                const date = sending ? moment(email.sent_at) : moment(email.received_at);
                return <List.Item
                    onClick={() => onSelectEmail(emailThread)}
                    className={`email-item ${selectedEmail?.id === emailThread.id ? 'selected' : ''}`}
                >
                    <List.Item.Meta
                        className={"leftAlign"}
                        title={
                            <div>
                                {!email.read_at && <Circle style={{color: '#4499FF', marginRight: '10px', fontSize: 12}}/>}
                                {email.subject}
                            </div>
                        }
                        description={sending ? `To: ${email.to}` : `From: ${email.from}`}
                    />
                    <div className="rightAlign">
                        {date.isAfter(moment().subtract(24, 'hours')) ?
                            date.fromNow() :
                            <>
                                {date.format('HH:MM')}<br/>
                                {date.format('DD/MM/YYYY')}
                            </>}
                    </div>

                </List.Item>
            }}/>

    );
};

export default EmailList;
// ProtectedRoute.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUser from "../utils/useUser"; // Import your custom hook

const ProtectedRoute = ({ children }) => {
    const [user] = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [user, navigate]);

    return user ? children : null;
};

export default ProtectedRoute;
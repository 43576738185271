import axios from "axios";
import campaigns from "./campaigns";
import companies from "./companies";
import mailbox from "./mailbox";
import users from "./users";

import cache from "../cache";

const url = "https://api.sflow.gopremiersoft.com";
const apiClient = axios.create({
    baseURL: url,
    headers: {
        'Authorization': 'Bearer '
    }
});

const refreshToken = (token) => {
    apiClient.defaults.headers['Authorization'] = 'Bearer ' + token;
}

const handleError = (error) => {
    console.error(error);
    if (error?.response?.data?.error) {
        throw error.response.data.error;
    } else {
        throw error;
    }
}

const api = {
    campaigns: campaigns(apiClient, handleError),
    companies: companies(apiClient, handleError),
    users: users(apiClient, handleError),
    mailbox: mailbox(apiClient, handleError, cache),
    refreshToken
}
export default api;


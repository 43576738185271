import React from 'react';
import {Table} from 'antd';

const TabEmails = ({
                       campaignDetails,
                   }) => {


    if (!campaignDetails) {
        return <></>
    }


    const checkCompanyRepeat = (text, record, index) => {
        const previousRecord =
            index > 0 ? emailResults[index - 1] : null;
        if (
            !previousRecord ||
            previousRecord.companyName !== record.companyName
        ) {
            return text;
        }
        return '';
    }


    const columns = [
        {title: "Company Name", dataIndex: "companyName", key: "companyName", render: checkCompanyRepeat},
        {title: "Email", dataIndex: "to_field", key: "to_field"},
        {title: "Views", dataIndex: "views", key: "views"},
        {title: "Clicks", dataIndex: "clicks", key: "clicks"},
        {title: "Sent at", dataIndex: "sent_at", key: "sent_at"},
    ];

    const emailResults = campaignDetails.companies.flatMap((company) =>
        company.emails.map((email) => ({
            ...email,
            companyName: company.name,
        }))
    );

    return (
        <Table
            dataSource={emailResults}
            columns={columns}
            pagination={false}
            rowKey="id"
        />
    );
};

export default TabEmails;
import React, {useCallback, useEffect, useState} from 'react';
import {Layout, Menu, Row, Col, Button, Modal} from 'antd';
import './Mailbox.css';
import EmailDetail from "./components/EmailDetail";
import EmailList from "./components/EmailList";

import {Send, Inventory2Outlined, DraftsOutlined, InboxOutlined, SendOutlined} from '@mui/icons-material';

import api from "../../api";
import cache from "../../cache";
import NewEmailForm from "./components/NewEmailForm";

const {Sider, Content} = Layout;

const Mailbox = () => {
    const [loadingList, setLoadingList] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState("inbox");
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [emails, setEmails] = useState([]);

    const [creatingNewEmail, setCreatingNewEmail] = useState(false);


    const refreshEmailList = useCallback(async (force, keepSelected) => {
        try {
            const mailListCache = await cache.mailbox.folder.get(selectedFolder);
            if (!force && mailListCache && mailListCache.length > 0) {
                setEmails(mailListCache);
            } else {
                setLoadingList(true);
            }

            if(!keepSelected) {
                setSelectedEmail(null);
            }
            const mailList = await api.mailbox.folder.get(selectedFolder);
            setEmails(mailList);
        } catch (e) {
            Modal.error({
                title: "Unable to fetch emails: " + e,
                okCancel: false,
            })

        } finally {
            setLoadingList(false);
        }
    }, [selectedFolder]);

    useEffect(() => {
        refreshEmailList().then();
    }, [selectedFolder, refreshEmailList]);

    const handleNewEmail = () => {
        setCreatingNewEmail(true);
    };

    const handleCancelNewEmail = () => {
        setCreatingNewEmail(false);
    }

    return (
        <>
            <Layout style={{padding: '24px 0', background: '#fff'}}>
                <Sider width={200} style={{background: '#fff'}}>
                    <Button
                        onClick={handleNewEmail}
                        className="flexCenterButton" type="primary" icon={<Send fontSize={"small"} style={{marginRight: 10}}/>}
                        style={{margin: '10px 10px', width: 'calc(100% - 20px)'}}>
                        New Email
                    </Button>
                    <Menu mode="inline" selectedKeys={selectedFolder} onSelect={({key}) => setSelectedFolder(key)}>
                        <Menu.Item key="inbox" icon={<InboxOutlined/>}>Inbox</Menu.Item>
                        <Menu.Item key="sent" icon={<SendOutlined/>}>Sent</Menu.Item>
                        {/*<Menu.Item key="outgoing" icon={<ForwardToInboxOutlined/>}>Outgoing</Menu.Item>*/}
                        <Menu.Item key="draft" icon={<DraftsOutlined/>}>Drafts</Menu.Item>
                        <Menu.Item key="archived" icon={<Inventory2Outlined/>}>Archived</Menu.Item>
                    </Menu>
                </Sider>
                <Content style={{padding: '0 24px', minHeight: 280}}>
                    <Row style={{display: 'flex', flexWrap: 'nowrap'}}>
                        <Col span={8} style={{maxWidth: 500, minWidth: 350}}>
                            <EmailList emails={emails} onSelectEmail={setSelectedEmail} selectedEmail={selectedEmail} loading={loadingList}/>
                        </Col>
                        <Col style={{flexGrow: 1}}>
                            <EmailDetail emailThread={selectedEmail} refreshList={refreshEmailList}/>
                        </Col>
                    </Row>
                </Content>
            </Layout>
            <NewEmailForm visible={creatingNewEmail} onCancel={handleCancelNewEmail} refreshList={refreshEmailList}/>
        </>
    );
};

export default Mailbox;
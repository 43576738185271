const moment = require("moment");
export const statusTranslator = (status) => {
    switch (status) {
        case campaignStatus.email_finished:
            return "Emails: Sent";
        case campaignStatus.email_waiting:
            return "Emails: Waiting to send emails";
        case campaignStatus.email_sending:
            return "Emails: Sending";
        case campaignStatus.linkedin_waiting:
            return "Linkedin: Waiting to start search";
        case campaignStatus.linkedin_searching:
            return "Linkedin: Searching";
        case campaignStatus.linkedin_finished:
            return "Linkedin: Finished search";
        default:
            return status;
    }
}

export const campaignStatus = {
    email_finished: "email_finished",
    email_waiting: "email_waiting",
    email_sending: "email_sending",
    linkedin_waiting: "linkedin_waiting",
    linkedin_searching: "linkedin_searching",
    linkedin_finished: "linkedin_finished",
}



export const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    a.sort();
    b.sort();

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}


export const cleanBody = (body) => {
    const bodySanitized = document.createElement('div');
    bodySanitized.innerHTML = body.replaceAll("https://m.gopremiersoft.com", "#track-premiersoft");
    const imgs = bodySanitized.getElementsByTagName("img")
    for (const img of imgs) {
        if (img.src && img.src.indexOf("#track-premiersoft") !== -1) {
            img.parentElement.removeChild(img);
        }
    }
    return bodySanitized.innerHTML;
}

export const renderDateTime = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
}

// export default {statusTranslator, arraysEqual, campaignStatus, cleanBody, renderDateTime}
// import campaigns from "./campaigns";
// import companies from "./companies";
import mailbox from "./mailbox";


const cache = {
    // campaigns: campaigns(apiClient, handleError),
    // companies: companies(apiClient, handleError),
    mailbox: mailbox(),
}
export default cache;


import React, {useState, useEffect, useRef} from 'react';
import {Typography, Input, Table, Row, Col, Button, Checkbox, Pagination, Select, Modal} from 'antd';
import {SearchOutlined} from '@ant-design/icons';

import ReactQuill from "react-quill";
import api from "../api";


const CompanyList = () => {
    const [industries, setIndustries] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [filters, setFilters] = useState({
        revenue: [], name: '', website: '', employee_count: [], industry: [],
    });

    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);


    const [jobName, setJobName] = useState('');
    const [subjectTemplate, setSubjectTemplate] = useState('');
    const [emailTemplate, setEmailTemplate] = useState('');
    const [rolePattern, setRolePattern] = useState('');

    const [showingSelected, setShowingSelected] = useState(false);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedCompaniesObjs, setSelectedCompaniesObjs] = useState([]);

    const [focusedInput, setFocusedInput] = useState(null);
    const [selectionRange, setSelectionRange] = useState({start: 0, end: 0});

    const quillRef = useRef(null);

    const [showCreateJobModal, setShowCreateJobModal] = useState(false);
    const openCreateJobModal = () => {
        setShowCreateJobModal(true);
    };
    const closeCreateJobModal = () => {
        setShowCreateJobModal(false);
    };
    const clearCreateJobModal = () => {
        setJobName("");
        setRolePattern("");
        setSubjectTemplate("");
        setEmailTemplate("");
        setSelectedCompanies([]);
        setSelectedCompaniesObjs([]);
    }
    const insertTemplateVariable = (variable) => {
        if (focusedInput === "subject") {
            const newSubject = [
                subjectTemplate.slice(0, selectionRange.start),
                variable,
                subjectTemplate.slice(selectionRange.end),
            ].join("");
            setSubjectTemplate(newSubject);
        } else if (focusedInput === "email") {
            if (quillRef.current) {
                const quillEditor = quillRef.current.getEditor();
                quillEditor.clipboard.dangerouslyPasteHTML(selectionRange.start, variable);
                quillEditor.setSelection(selectionRange.start + variable.length);
            }
        }
    };


    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const data = await api.companies.industries();
                setIndustries(data.map(industry => capitalize(industry)));
            } catch (error) {
                console.error('Error fetching industries');
            }
        }
        fetchIndustries();
    }, [])

    useEffect(() => {

        const fetchCompanies = async () => {
            try {
                setLoading(true);
                const data = await api.companies.list(filters.revenue,
                    filters.name,
                    filters.website,
                    filters.employee_count,
                    filters.industry,
                    currentPage,
                    itemsPerPage);

                setCompanies(data.data);
                setTotalItems(data.total);
            } catch (error) {
                console.error('Error fetching companies:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCompanies();
    }, [filters, currentPage, itemsPerPage]);

    const capitalize = (text) => {
        if (text?.length > 0) return text[0].toUpperCase() + text.substring(1)
        return text;
    }


    const handleFilterChange = (e) => {
        setFilters({
            ...filters, [e.target.name]: e.target.value,
        });
    };

    const handleCompanySelect = (company) => {
        if (selectedCompanies.includes(company.id)) {
            const newCompanies = selectedCompanies.filter((id) => id !== company.id);
            setSelectedCompanies(newCompanies);
            setSelectedCompaniesObjs(selectedCompaniesObjs.filter((c) => c.id !== company.id));
            if (newCompanies.length === 0) {
                setShowingSelected(false);
            }
        } else {
            setSelectedCompanies([...selectedCompanies, company.id]);
            setSelectedCompaniesObjs([...selectedCompaniesObjs, company]);
        }
    };

    const clearSelectedCompanies = () => {
        if (selectedCompanies.length > 0) {
            Modal.confirm({
                "title": "Do you want to clear the selected companies?",
                onOk() {
                    setSelectedCompanies([]);
                    setSelectedCompaniesObjs([]);
                    setShowingSelected(false);
                }
            });
        }
    }

    const createJob = async (jobData) => {
        try {
            if (!jobName || !subjectTemplate || !emailTemplate || !rolePattern || selectedCompanies.length === 0) {
                Modal.error({
                    title: "Please fill in all fields and select at least one company.",
                    okCancel: false,
                })

            } else {
                const data = await api.campaigns.create(jobName,
                    subjectTemplate,
                    emailTemplate,
                    rolePattern,
                    selectedCompanies);

                console.log(data);
                Modal.success({
                    title: "Campaign created successfully",
                    okCancel: false,
                })

                closeCreateJobModal();
                clearCreateJobModal();
            }
        } catch (e) {
            Modal.error({
                title: "Error creating campaign: " + e,
                okCancel: false,
            });
        }
    };

    const columns = [{
        title: 'Select', dataIndex: 'select', key: 'select', render: (text, record) => (<Checkbox
            checked={selectedCompanies.includes(record.id)}
            onChange={() => handleCompanySelect(record)}
        />),
    }, {title: 'Name', dataIndex: 'name', key: 'name', render: capitalize}, {
        title: 'Website', dataIndex: 'website', key: 'website'
    }, {title: 'Industry', dataIndex: 'industry', key: 'industry', render: capitalize}, {
        title: 'Revenue', dataIndex: 'revenue', key: 'revenue'
    }, {title: 'Employee Count', dataIndex: 'employee_count', key: 'employee_count'},];


    // noinspection JSValidateTypes
    return (<><Row gutter={[32, 16]} style={{padding: '16px'}}>
            <Col span={24}>
                <h1>Companies</h1>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: '20px',}}>
                    <div>
                        <Button type={!showingSelected ? "primary" : "default"}
                                onClick={() => setShowingSelected(false)}>All companies</Button>
                        <Button type={showingSelected ? "primary" : "default"} onClick={() => setShowingSelected(true)}
                                disabled={selectedCompanies.length === 0}>Show
                            selected companies</Button>
                    </div>
                    <div>
                        <span style={{padding: '10px'}}>
                            Companies selected: {selectedCompanies.length}
                        </span>
                        <Button disabled={selectedCompanies.length === 0} type="dashed"
                                onClick={clearSelectedCompanies}>
                            Clear selection
                        </Button>
                        <Button disabled={selectedCompanies.length === 0} style={{margin: '0 10px'}} type="primary"
                                onClick={openCreateJobModal}>
                            Create Campaign
                        </Button>
                    </div>
                </div>

                <Input.Group>
                    <Input
                        placeholder="Name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                        style={{width: '20%'}}
                        prefix={<SearchOutlined/>}
                    />

                    <Input
                        placeholder="Website"
                        name="website"
                        value={filters.website}
                        onChange={handleFilterChange}
                        style={{width: '20%'}}
                        prefix={<SearchOutlined/>}
                    />
                    <Select
                        placeholder="Industry"
                        name="industry"
                        mode="tags"
                        value={filters.industry}
                        onChange={(value) => setFilters({...filters, industry: value})}
                        style={{width: '20%'}}
                    >

                        {industries.map((industry) => <Select.Option value={industry}
                                                                     key={industry}>{industry}</Select.Option>)}
                    </Select>
                    <Select
                        placeholder="Revenue"
                        name="revenue"
                        mode="tags"
                        value={filters.revenue}
                        onChange={(value) => setFilters({...filters, revenue: value})}
                        style={{width: '20%'}}
                    >
                        <Select.Option value="5 Million to 10 Million">5 Million to 10 Million</Select.Option>
                        <Select.Option value="10 Million to 25 Million">10 Million to 25 Million</Select.Option>
                        <Select.Option value="25 Million to 50 Million">25 Million to 50 Million</Select.Option>
                        <Select.Option value="50 Million to 100 Million">50 Million to 100 Million</Select.Option>
                        <Select.Option value="100 Million to 250 Million">100 Million to 250 Million</Select.Option>
                        <Select.Option value="250 Million to 500 Million">250 Million to 500 Million</Select.Option>
                        <Select.Option value="500 Million to 1 Billion">500 Million to 1 Billion</Select.Option>
                    </Select>
                    <Select
                        placeholder="Employee count"
                        name="employee_count"
                        mode="tags"
                        value={filters.employee_count}
                        onChange={(value) => setFilters({...filters, employee_count: value})}
                        style={{width: '20%'}}
                    >
                        <Select.Option value="1-10">1-10</Select.Option>
                        <Select.Option value="11-50">11-50</Select.Option>
                        <Select.Option value="51-200">51-200</Select.Option>
                        <Select.Option value="201-500">201-500</Select.Option>
                        <Select.Option value="501-1000">501-1000</Select.Option>
                        <Select.Option value="1001-5000">1001-5000</Select.Option>
                        <Select.Option value="5001-10000">5001-10000</Select.Option>
                        <Select.Option value="10001+">10001+</Select.Option>
                    </Select>
                </Input.Group>

                <Table
                    dataSource={showingSelected ? selectedCompaniesObjs : companies}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    size={"small"}
                    style={{marginTop: '16px'}}
                    loading={loading}
                    onRow={(record) => ({
                        onClick: () => handleCompanySelect(record),
                    })}
                />

                { !showingSelected &&
                <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={totalItems} // You need to fetch and set the total number of items from the server
                    onChange={(page) => {
                        setCurrentPage(page);
                    }}
                    onShowSizeChange={(current, size) => {
                        setItemsPerPage(size);
                    }}
                />
                }
            </Col>
        </Row>
            <Modal
                open={showCreateJobModal}
                width={'70vw'}
                style={{maxWidth: '1500px'}}
                onCancel={closeCreateJobModal}
                footer={[<Button key="cancel" onClick={closeCreateJobModal}>
                    Cancel
                </Button>, <Button key="create" type="primary" onClick={createJob}>
                    Create Campaign
                </Button>,]}
            >
                <h2>Create Campaign</h2>
                <Input
                    placeholder="Campaign name"
                    value={jobName}
                    onChange={(e) => setJobName(e.target.value)}
                    style={{marginBottom: '16px'}}
                />
                <Input
                    placeholder="Role pattern"
                    value={rolePattern}
                    onChange={(e) => setRolePattern(e.target.value)}
                    style={{marginBottom: '16px'}}
                />
                <Input
                    placeholder="Subject template"
                    value={subjectTemplate}
                    onChange={(e) => setSubjectTemplate(e.target.value)}
                    onFocus={() => setFocusedInput("subject")}
                    onSelect={(e) => setSelectionRange({start: e.target.selectionStart, end: e.target.selectionEnd})}
                    style={{marginBottom: '16px'}}
                />
                <ReactQuill
                    placeholder="Email template"
                    value={emailTemplate}
                    onChange={setEmailTemplate}
                    onFocus={() => setFocusedInput("email")}
                    onChangeSelection={(range) => range ? setSelectionRange({
                        start: range.index,
                        end: range.index + range.length
                    }) : null}
                    style={{marginBottom: '50px', height: '25vh'}}
                    ref={quillRef}

                />


                <div>
                    <Typography.Title level={5}>Template Variables: <small>(You can click on a variable to add to the
                        field)</small></Typography.Title>
                    <pre>
                        <Button type={"link"}
                                onClick={() => insertTemplateVariable("|COMPANY_NAME|")}>|COMPANY_NAME|</Button><br/>
                        <Button type={"link"}
                                onClick={() => insertTemplateVariable("|CONTACT_FIRST_NAME|")}>|CONTACT_FIRST_NAME|</Button><br/>
                        <Button type={"link"}
                                onClick={() => insertTemplateVariable("|CONTACT_LAST_NAME|")}>|CONTACT_LAST_NAME|</Button><br/>
                        <Button type={"link"}
                                onClick={() => insertTemplateVariable("|CONTACT_FULL_NAME|")}>|CONTACT_FULL_NAME|</Button><br/>
                        <Button type={"link"}
                                onClick={() => insertTemplateVariable("|CONTACT_ROLE|")}>|CONTACT_ROLE|</Button><br/>
                        <Button type={"link"}
                                onClick={() => insertTemplateVariable("|JOBS_OPENINGS_FOUND|")}>|JOBS_OPENINGS_FOUND|</Button><br/>
                        <Button type={"link"}
                                onClick={() => insertTemplateVariable("|FIRST_JOBS_OPENING_FOUND|")}>|FIRST_JOBS_OPENING_FOUND|</Button>
                    </pre>
                    <Typography.Title level={5}>Role Pattern:</Typography.Title>
                    <p>Add multiple roles by separating them with a |.</p>
                    <p>As example: Technology|CTO|Software</p>
                </div>
            </Modal>
        </>


    );
};

export default CompanyList;
